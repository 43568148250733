const tools = require('./tools');

class Lazy {
    constructor(targets, loadFn, threshold=0.01, rootMargin= 0) {
        this.elementList = [];

        this.threshold = threshold;
        this.rootMargin = rootMargin;

        this.loadFn = loadFn;
        this.intersectionObserver = null;

        if(typeof targets === "string") targets = document.querySelectorAll(targets);
        if(typeof targets.length === typeof undefined) this.elementList.push(targets);
        else tools.each(targets, elt => this.elementList.push(elt));

        this.init();
    }

    init() {

        if(!this.loadFn) {
            this.loadFn = (elt) => {
                const imgSrc = elt.getAttribute('data-lazy');
                if(imgSrc) {
                    const img = document.createElement('img');
                    img.onload = () => {
                        elt.src = imgSrc;
                    }

                    img.src = imgSrc;
                }
            }
        }

        if (typeof IntersectionObserver !== typeof undefined) {
            this.proceedWithObserver();
        } else {
            const fn = (lazy) => {
                if(this.elementList.length > 0) {
                    let loadedIndex = []
                    tools.each(lazy.elementList, (elt, i) => {
                        const topLimit = elt.getBoundingClientRect().top + elt.offsetHeight * this.threshold;
                        const bottomLimit = elt.getBoundingClientRect().bottom + elt.offsetHeight * this.threshold;

                        if(topLimit < window.innerHeight + this.rootMargin
                            && bottomLimit > this.rootMargin) {
                            lazy.execLoad(elt);
                            loadedIndex.push(i);
                        }
                    })
                    if(loadedIndex.length > 0) {
                        this.elementList = this.elementList.reduce((a,b,i) => {
                            if(loadedIndex.indexOf(i) === -1) {
                                a.push(b);
                            }
                            return a;
                        }, [])
                    }
                }
            }
            fn(this);
            tools.doOnScroll({fn: fn, arg: this}, true, true);
        }
    }

    execLoad(elt, i) {
        if(!elt.classList.contains('lazy-loaded')) {
            this.loadFn(elt);
            elt.classList.add('lazy-loaded')

            if(i) {
                this.elementList = this.elementList.splice(i, 1);
            }
        }
    }

    proceedWithObserver() {
        const callback = (entries, observer)  => {
            entries.forEach(entry => {
                if(entry.isIntersecting) {
                    observer.unobserve(entry.target);
                    this.execLoad(entry.target)
                }
            })
        };

        this.intersectionObserver = new IntersectionObserver(callback, {threshold: this.threshold, rootMargin: this.rootMargin + 'px 0px'});

        tools.each(this.elementList, elt => this.intersectionObserver.observe(elt));
    }
}

export default Lazy;
