const tools = require('../tools');
import { v4 as uuidv4} from 'uuid';
const kaufProductInfo = {
    props: [
        'selection',
        'update_mode',
        'labels'
    ],

    data: function(vm) {
        return {
            addAmount: null,
            uploadedFile: null,
            fileTooLarge: false,
            updated: false
        }
    },

    template:
        `
        <section class="info kaufen">
         <div class="quantity" :class="{error: qtyMatchNotMinOrder}">
                <span>{{labels.bechermenge_label}}</span>
                <div class="qtywrapper">
                    <span :class="{active: qtyMatchNotMinOrder}" class="round" v-on:click="updateQty(minOrderAmount)"
                        :title="labels.minorderamount_label +': ' + minOrderAmount"
                    >
                        <svg fill='none' stroke='#000' stroke-width='10' stroke-dashoffset='0' stroke-dasharray='0' stroke-linecap='round' stroke-linejoin='round' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100'><line x1="20" y1="20" x2="80" y2="20" />
                            <line x1="50" y1="35" x2="50" y2="85" />
                            <polyline fill="none" points="30,50 50,35 70,50" />
                        </svg>
                    </span>
                    <input class="qtyfield input" type="text"
                    v-model.number="addAmount"
                    v-on:blur="checkValue()"
                    v-on:keyup.enter="addItem($event)" @keypress="isNumber"/>
                </div>
                <span>{{labels.piece_label}}</span>
                <div class="fontSmall">{{labels.minorderamount_label}}: {{ minOrderAmount }}</div>
            </div>
            <div class="file" v-if="needFile">
                <span class="label">{{labels.uploadlogo_label}}</span>
                <input id="fileUpload"
                       type="file"
                       @change="uploadFile"
                       accept="image/*"
                       ref="fileInput">
                <label for="fileUpload" class="input fileUpload">{{fileInputLabel}}</label>
                <br/>
                <span v-if="fileTooLarge" class="error">{{ labels.file_size_error }}</span>
                <span v-if="uploadedFile!==null">{{ uploadedFile.name }}</span>
            </div>

            <div class="buttons">
                <div class="fontRegular addItem-wrapper" :class="{visible: selectionValid}">
                    <div class="button addItem">
                        <p v-on:click="addItem()" class="page-color">
                            <span v-html="getAddBtnLabel()"></span>
                            <slot></slot>
                        </p>
                    </div>
                    <div class="button cancel" v-if="update_mode">
                        <p v-on:click="cancelUpdate()" class="page-color">
                            <span>{{labels.cancel_label}}</span>
                            <slot></slot>
                        </p>
                    </div>
                </div>
            </div>
        </section>
        `,

    beforeUpdate() {
        if(!this.updated && this.update_mode) {
            this.updated = true;
            if(this.selection.amount > 0) {
                this.addAmount = this.selection.amount;
            }
            if(this.selection.file !== null && typeof this.selection.file !== 'undefined') {
                this.uploadedFile = this.selection.file;
            }
        // } else if(!this.addAmount || this.addAmount < this.minOrderAmount) {
        } else if(!this.addAmount) {
            this.addAmount = this.minOrderAmount | null;
        }
    },

    computed: {
        fileInputLabel() {
            return this.uploadedFile ? this.labels.changefile_label : this.labels.choosefile_label;
        },

        needFile() {
            return this.selected_article && this.selection && this.selection.print && this.selection.print.value !== 'unbedruckt';
        },

        selected_article() {
            return (this.selection !== null && this.selection.article !== null) ? this.selection.article : null;
        },

        minOrderAmount() {
            return (this.selection !== null && this.selection.min_order_amount !== null) ? this.selection.min_order_amount : 1
        },

        selectionValid() {
            return this.addAmount - this.minOrderAmount >= 0;
        },

        qtyMatchNotMinOrder() {
            return this.addAmount && this.minOrderAmount > 1 && this.addAmount < this.minOrderAmount;
        }
    },

    methods: {
        checkValue() {
            setTimeout(() => {
                if(this.qtyMatchNotMinOrder) {
                    this.updateQty(this.minOrderAmount)
                }
            }, 700)
        },

        updateQty(nb) {
            this.addAmount = nb * 1;
        },

        addItem: function() {
            if(this.qtyMatchNotMinOrder) {
                this.updateQty(this.minOrderAmount)
                return
            }

            if(this.selectionValid) {
                let x = Math.max(this.minOrderAmount, this.addAmount);
                if (x <= 0) {
                    return
                }
                this.selection.setAmount(x);
                this.selection.setFile(this.uploadedFile);
                this.$emit('add-to-basket', this.selection)

                if (this.$refs.fileInput !== undefined) {
                    this.$refs.fileInput.value = ""
                }
            }
        },

        isNumber(evt) {
            return tools.isNumber(evt)
        },

        uploadFile: function(evt) {
            this.uploadedFile = null;
            if(evt.target.files[0]) {
                if(evt.target.files[0].size > this.getFileuploadMaxsize()) {
                    this.fileTooLarge = true;
                } else {
                    this.fileTooLarge = false;
                    this.uploadedFile = evt.target.files[0];
                }
            }
        },

        getAddBtnLabel() {
            return this.update_mode ? this.labels.update_label : this.labels.add_label
        },

        cancelUpdate() {
            console.log('cancel from kaufinfo');
            this.$emit('cancel-update');
        },

        getFileuploadMaxsize() {
            return this.$root.$el.dataset.fileuploadMaxsize
        }

    },

    mounted() {
        this.addAmount = this.minOrderAmount;
    }
}

export default kaufProductInfo;
