class Bubble {
    constructor(parent, origY= null) {
        this.wrapper = parent;
        this.elt = document.createElement('span');
        this.elt.classList.add('bubble');
        this.baseSize = parent.offsetWidth * 0.05;
        this.baseSize = this.baseSize < 20 ? 20 : this.baseSize;
        this.strokeWidth = 2;
        this.width;
        this.height;
        this.x;
        this.y = origY;
        this.rightLeftDuration = Math.floor(Math.random() * 10) + 10;
        this.direction = Math.floor(Math.random * 100) / 2 > 49.5;
        this.lastDirectionCount = 0;

        this.blasted = false;
        this.setInitialState();

        this.elt.addEventListener('click', e => {
            this.blast();
        })
    }

    blast() {
        if(!this.blasted) {
            this.elt.innerHTML = this.getBlastTemplate();
            this.blasted = true;
            setTimeout(()=>this.destroy(), 250)
        }
    }


    destroy() {
        this.wrapper.removeChild(this.elt);
    }

    hide() {
        this.elt.classList.add('hidden');
    }

    show() {
        this.elt.classList.remove('hidden');
    }

    setInitialState() {
        let size = Math.floor(Math.random() * this.baseSize);
        this.width = size;
        this.height = size;
        this.y = this.y ? this.y : this.wrapper.offsetHeight + this.height + 10;
        this.x = Math.floor(Math.random() * (this.wrapper.offsetWidth - 2 * size)) + size;
        this.elt.style.width = this.width + 'px';
        this.elt.style.height = this.height + 'px';

        this.verticalSpeed = Math.floor(Math.random() * 10) + 2;
        this.horizontalSpeed = 1;

        this.setPosition();

        this.elt.innerHTML = this.getTemplate();
        this.wrapper.insertAdjacentElement('beforeend', this.elt)
    }

    updatePostition() {
        this.y -= this.verticalSpeed;
        // if(this.y <= 0 - this.wrapper.offsetHeight) {
        if(this.y <= 0) {
            this.blast();
        } else {
            if(this.lastDirectionCount > this.rightLeftDuration) {
                this.direction = !this.direction;
                this.lastDirectionCount = 0;
            } else {
                this.lastDirectionCount++
            }
            this.x = this.direction ? this.x + this.horizontalSpeed: this.x - this.horizontalSpeed;
            this.setPosition();
        }
        return this.blasted;
    }

    setPosition() {
        this.elt.style.transform = 'translate(' + this.x + 'px, ' + this.y + 'px)'
    }

    getTemplate() {
        return '<svg xmlns="http://www.w3.org/2000/svg" width="'+this.width+'" height="'+this.height+'" viewBox="0 0 '+this.width+' '+this.height+'">'+
            '<circle cx="'+this.height / 2+'" cy="'+this.height / 2+'" r="'+(this.height - 2 * this.strokeWidth) / 2+'" stroke-width="'+this.strokeWidth+'"></circle>'+
            '</svg>';
    }

    getBlastTemplate() {
        return '<svg class="blast" xmlns="http://www.w3.org/2000/svg" width="45.212" height="47.539" viewBox="0 0 45.212 47.539">'+
            '<polygon points="22.606 1.769 26.005 13.308 35.537 5.971 31.505 17.304 43.529 16.971 33.606 23.769 43.529 30.568 31.505 30.235 35.537 41.568 26.005 34.231 22.606 45.769 19.207 34.231 9.675 41.568 13.707 30.235 1.683 30.568 11.606 23.769 1.683 16.971 13.707 17.304 9.675 5.971 19.207 13.308 22.606 1.769" stroke-width="'+this.strokeWidth+'" stroke-miterlimit="10"/>'+
            '</svg>';
    }
}

export default Bubble;
