const tools = require("./tools");
import Bubble from './Bubble';

class TeamBubbles {

    constructor(elt) {
        this.elt = elt;
        this.wrapper =  elt.querySelector('.image-grid-fancybox-content');
        this.wrapper.style.position = "relative";
        this.bubbles = [];
        this.bubbleFrame = null;

        this.init();
    }

    init() {
        this.addBubbles();
        const loop = () => {
            let stop = true;
            tools.each(this.bubbles, bubble  => {
                if(!bubble.updatePostition()) {
                    stop = false;
                }
            });

            if(!stop) {
                this.bubbleFrame = window.requestAnimationFrame(loop);
            }
        }

        loop();
    }

    addBubbles() {
        let nb = Math.floor(Math.random() * 10) + 7;
        for(let i = 0; i < nb; i++) {
            const bubble = new Bubble(this.wrapper);
            this.bubbles.push(bubble);
        }
    }

    hideBubbles() {
        cancelAnimationFrame(this.bubbleFrame);
        tools.each(this.bubbles, bubble => bubble.blast());
        this.bubbles = [];
    }
}

export default TeamBubbles;
