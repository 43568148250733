const tools = require('./tools');
import Bubble from './Bubble';

class Collapsible {
    constructor(elt, btnSelector= 'button', collapsibeZoneSelector='.collapsible-zone') {
        this.elt = elt;

        if(!tools.isEditMode()) {
            this.button = elt.querySelector(btnSelector);
            this.wrapper =  elt.querySelector(collapsibeZoneSelector);
            this.area = this.wrapper.querySelector('div');

            this.bubbles = [];
            this.bubbleFrame = null;

            if(!this.elt.collapsiblefn && this.button && this.wrapper && this.area) {
                this.elt.collapsiblefn = true;
                this.init();
            }
        }
    }

    collapsedClass() { return 'collapsed'};

    btnClick(e) {
        if(this.isClosed()) {
            this.open();
        } else {
            this.close();
        }
    }

    init() {
        this.button.addEventListener('click', e => this.btnClick(e))

        tools.doOnWindowResize({fn: arg => {
                if(arg.isOpen()) {
                    arg.resizeWrapper();
                }
            }
            , arg: this})
    }

    open() {
        this.resizeWrapper();
        this.elt.classList.remove(this.collapsedClass());

        this.showBubbles();
    }

    close() {
        this.wrapper.style.height = null;
        this.wrapper.style.maxHeight = null;
        this.elt.classList.add(this.collapsedClass());
        this.hideBubbles();
    }

    showBubbles() {
        this.addBubbles();
        const loop = () => {
            let stop = true;
            tools.each(this.bubbles, bubble  => {
                if(!bubble.updatePostition()) {
                    stop = false;
                }
            });

            if(!stop) {
                this.bubbleFrame = window.requestAnimationFrame(loop);
            }
        }

        loop();
    }

    hideBubbles() {
        cancelAnimationFrame(this.bubbleFrame);
        tools.each(this.bubbles, bubble => bubble.blast());
        this.bubbles = [];
    }

    resizeWrapper() {
        const height = this.area.offsetHeight + 'px';
        this.wrapper.style.height = height;
        this.wrapper.style.maxHeight = height;
    }

    isOpen() {return !this.isClosed();}

    isClosed() {return this.elt.classList.contains('collapsed')}

    addBubbles() {

        let nb = Math.floor(Math.random() * 10) + 7;
        for(let i = 0; i < nb; i++) {
            this.bubbles.push(new Bubble(this.wrapper, this.wrapper.style.height.replace('px', '') * 1));
        }
        // this.bubbles.push(new Bubble(this.wrapper));

    }
}
export default Collapsible;
