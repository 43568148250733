import { v4 as uuidv4} from 'uuid';

class Selection {
    constructor(options = {
        article: null,
        group: null,
        size: null,
        optic: null,
        print: null,
        min_order_amount: 1,
        showDetails: false,
        amount: 0,
        tab_id: 0,
        file:null,
    }) {
        this.article = null;
        this.group = null;
        this.size = null;
        this.optic = null;
        this.print = null;
        this.min_order_amount = null;
        this.showDetails = false;
        this.amount = 0;
        this.tab_id = 0;
        this.file = null;
        this.id;


        this.update(options);

    }

    clone() {
        return new Selection().update(this).getNewId();
    }

    update(options) {
        this.article = options.article ? options.article : this.article;
        this.group = options.group ? options.group : this.group;
        this.size = options.size ? options.size : this.size;
        this.optic = options.optic ? options.optic : this.optic;
        this.print = options.print ? options.print : this.print;
        this.min_order_amount = options.min_order_amount ? options.min_order_amount : this.min_order_amount;
        this.showDetails = options.showDetails ? options.showDetails : this.showDetails;
        this.amount = options.amount ? options.amount : this.amount;
        this.tab_id = options.tab_id ? options.tab_id : this.tab_id;
        this.file = options.file ? options.file : this.file;
        return this;
    }

    reset() {
        this.article = null;
        this.group = null;
        this.size = null;
        this.optic = null;
        this.print = null;
        this.min_order_amount = null;
        this.showDetails = false;
        this.amount = 0;
        this.file = null;
        this.id = null;
        return this;
    }

    setTabId(tab_id) {
        this.tab_id = tab_id;
        return this;
    }

    setGroup(group) {
        this.group = group;
        this.updateSize();
    }


    setSize(size) {
        this.size = size;
        this.updateOptic();
    }

    setOptic(optic) {
        this.optic = optic;
        this.updatePrint();
        // this.updateArticle();
    }

    setPrint(print) {
        this.setArticle(null);
        this.print = print
        this.updateArticle();
    }

    setArticle(article) {
        this.article = article;
    }

    setMinOrderAmount(amount) {
        this.min_order_amount = amount;
    }

    updateSize() {
        let sizes = this.getSizes();
        this.setSize(sizes.length > 0 ? sizes[0] : null);
    }

    updateOptic() {
        let optics = this.getOptics();
        this.setOptic(optics.length > 0 ? optics[0] : null);
    }

    updatePrint() {
        let prints = this.getPrints();
        this.setPrint(prints.length === 1 ? prints[0] : null);
    }

    updateArticle() {
        let article = null;
        if (this.print !== null) {
            article = this.group.articles.find(e => e.id === this.print.articleId)
        } else if (this.optic !== null) {
            article = this.group.articles.find(e => e.id === this.optic.articleId)
        } else if (this.size !== null) {
            article = this.group.articles.find(e => e.id === this.size.articleId)
        } else if (this.group != null && this.group.articles.length === 1) {
            article = this.group.articles[0]
        }

        this.setArticle(typeof article === "undefined" ? null : article);
    }

    getSizes() {
        let result = [];
        if(this.group && ((Array.isArray(this.group.sizes) && this.group.sizes.length > 0))) {
            result = this.group.sizes;
        }
        return result;
    }

    getOptics() {
        let result = [];
        if (this.group !== null && ((Array.isArray(this.group.optics) && this.group.optics.length > 0))) {
            result = this.group.optics;
        } else if (this.size !== null && ((Array.isArray(this.size.optics) && this.size.optics.length > 0))) {
            result = this.size.optics;
        }
        return result;
    }

    getPrints() {
        let result = [];
        if (this.optic !== null && ((Array.isArray(this.optic.prints) && this.optic.prints.length > 0))) {
            result = this.optic.prints;
        } else if (this.size !== null && ((Array.isArray(this.size.prints) && this.size.prints.length > 0))) {
            result = this.size.prints;
        }

        return result;
    }

    hasSizes() {
        return this.getSizes().length > 0;
    }

    hasOptics() {
        return this.getOptics().length > 0;
    }

    hasPrints() {
        return this.getPrints().length > 0;
    }

    hasArticle() {
        return this.article !== null;
    }

    getNewId() {
        this.id = uuidv4();
        return this;
    }

    isValid() {
        return this.article !== null && typeof this.article !== 'undefined';
    }

    setAmount(quantity) {
        //todo: variable checks
        this.amount = quantity;
    }

    setFile(uploadedFile) {
        //todo: uploadedFile checks
        this.file = uploadedFile;
    }

    isSameGroup(other) {
        return this.group && other && this.group.id && other.id && this.group.id === other.id;
    }

    isSameSize(other) {
        return this.size === other;
    }

    isSameOptic(other) {
        return this.optic === other;
    }
}

export default Selection;

