import TeamBubbles from "./TeamBubbles";

require('@babel/polyfill');
import tools from "./tools";
import vueKalkualtorApp from'./kalkulator/vueKalkulator';
import Lazy from "./Lazy";
import Collapsible from "./Collapsible";
import {tns} from "../../../../node_modules/tiny-slider/src/tiny-slider.js"
import UserDevice from "./UserDevice";
import ANIMATED_LOGO from './logoAnimationScript';
import { Fancybox } from "@fancyapps/ui/dist/fancybox/fancybox.esm.js";

if (!Array.prototype.find) {
    Object.defineProperty(Array.prototype, 'find', {
        value: function(predicate) {
            // 1. Let O be ? ToObject(this value).
            if (this == null) {
                throw TypeError('"this" is null or not defined');
            }

            var o = Object(this);

            // 2. Let len be ? ToLength(? Get(O, "length")).
            var len = o.length >>> 0;

            // 3. If IsCallable(predicate) is false, throw a TypeError exception.
            if (typeof predicate !== 'function') {
                throw TypeError('predicate must be a function');
            }

            // 4. If thisArg was supplied, let T be thisArg; else let T be undefined.
            var thisArg = arguments[1];

            // 5. Let k be 0.
            var k = 0;

            // 6. Repeat, while k < len
            while (k < len) {
                // a. Let Pk be ! ToString(k).
                // b. Let kValue be ? Get(O, Pk).
                // c. Let testResult be ToBoolean(? Call(predicate, T, « kValue, k, O »)).
                // d. If testResult is true, return kValue.
                var kValue = o[k];
                if (predicate.call(thisArg, kValue, k, o)) {
                    return kValue;
                }
                // e. Increase k by 1.
                k++;
            }

            // 7. Return undefined.
            return undefined;
        },
        configurable: true,
        writable: true
    });
}

const app = {
    userDevice: null,
    init() {
        app.domSettings();
        vueKalkualtorApp.init();
        app.lazyness();
        app.collapsibles();
        app.becherStage();
        app.becherStageCount();
        app.hamburgerMenu();
        app.initLogoAnimation();
        app.scrollNavigation();
        app.cookiesAlert();
        app.initFancyLightbox();
    },

    domSettings() {
        this.userDevice = new UserDevice();
        document.getElementsByTagName('html')[0].classList.remove('no-js');
    },

    lazyness() {
        new Lazy('.lazy');
    },

    collapsibles() {
        tools.each(document.querySelectorAll('.collapsible-elt'), elt => new Collapsible(elt));
    },

    becherStage() {
        let isInEditMode = tools.isEditMode();
        let sliderContainer = document.getElementById('cup-slider');

        // only turn on slider when not in edit mode
        if (!isInEditMode && sliderContainer != null && sliderContainer.childElementCount > 0) {
            var slider = tns({
                container: '#cup-slider',
                speed: sliderContainer.dataset.interval,
                autoplay: true,
                lazyload: true,
                mouseDrag: true,
                autoplayButtonOutput: false,
                nav: true,
                controls: true
            });
        }
    },

    becherStageCount() {
        // create slides only if slides exist on page
        let cupSlider = document.getElementById('cup-slider');
        if (cupSlider) {
            let hasSlides = cupSlider.childElementCount;
            if (hasSlides > 0) {
                let nav = document.querySelector('.tns-nav');
                let children = nav.children;

                for (let i = 0; i < children.length; i++) {
                    let slideNumber = children[i].getAttribute("data-nav");
                    let slideCount = parseInt(slideNumber) + 1;
                    children[i].innerHTML = slideCount;
                }
            }
        }
    },

    hamburgerMenu() {
        let navMenu = document.querySelector(".navis");
        let hamburgerButton = document.getElementById("mobile-hamburger");
        let body = document.querySelector('body');
        let navNav = navMenu.querySelector('nav > ul');
        // let navItems = navNav.querySelectorAll('ul');

        let children = navNav.children;
        let limit = children.length;

        hamburgerButton.addEventListener("click", function () {
            if (navMenu.style.display === "block") {
                disableMobileNav();
                animate(limit, "remove")
            } else {
                enableMobileNav();
                animate(limit, "add");
            }
        });

        // close navigation and enable desktop navigation when resizing back to desktop size
        // while mobile navigation is still open
        window.addEventListener("resize", function mobileNavChecker() {
            let width = window.innerWidth;
            const hamburgerView = 1200;
            if ((navMenu.style.display === "block" && width > hamburgerView) || (navMenu.style.display === "none" && width > hamburgerView)) {
                disableMobileNav();
                navMenu.style.display = "block";
            } else if (navMenu.style.display === "block" && width <= hamburgerView) {
                navMenu.style.display = "none";
            }
        });

        function disableMobileNav() {
            navMenu.style.display = "none";
            hamburgerButton.classList.remove("z-index-two");
            hamburgerButton.getElementsByClassName("c-hamburger")[0].classList.remove("is-active");
            body.classList.remove("mobile-nav-body-scroll-behaviour");
            navNav.classList.remove("mobile-nav-nav-scroll-behaviour");
        }

        function enableMobileNav() {
            navMenu.style.display = "block";
            hamburgerButton.classList.add("z-index-two");
            hamburgerButton.getElementsByClassName("c-hamburger")[0].classList.add("is-active");
            body.classList.add("mobile-nav-body-scroll-behaviour");
            navNav.classList.add("mobile-nav-nav-scroll-behaviour");
        }

        function animate(limit, cl) {
            var i = 0;
            var ref = setInterval(() => {
                if (cl === "add") children[i].classList.add("nav-appear");
                else if (cl === "remove") children[i].classList.remove("nav-appear");
                ++i;
                if (i === limit) clearInterval(ref);
            }, 75);
        }
    },

    initLogoAnimation() {
        let min = 5000;
        let max = 15000;
        var zahl = Math.random() * (max - min) + min;
        setTimeout( () => ANIMATED_LOGO.restart(), zahl)

        let logoElt = document.querySelector('#logo')
        if(logoElt) {
            setTimeout( ()=>logoElt.addEventListener('mouseenter', () => ANIMATED_LOGO.restart()), 100)
        }
    },

    scrollNavigation() {
        let minified = false;
        window.addEventListener('scroll', function(e) {
            if(window.scrollY > 0) {
                document.body.classList.add('nav-minified');
                if(document.body.offsetHeight > window.innerHeight + 120) {
                    document.body.classList.add('header-minified');
                }
                minified = true;
            } else if(minified) {
                document.body.classList.remove('nav-minified')
                document.body.classList.remove('header-minified');
                minified = false;
            }
        });
    },

    cookiesAlert() {
        let cookieName = 'cupsystems-cookies-accepted=true';
        let cookieAlertElt = document.getElementById('cookieAlert');
        if (cookieAlertElt) {

            if (document.cookie.indexOf(cookieName) === -1) {
                cookieAlertElt.classList.remove('accepted');
            }

            let cookieBtn = cookieAlertElt.getElementsByClassName('ca-accept')[0];

            cookieBtn.addEventListener('click', evt => {
                let d = new Date();
                d.setTime(d.getTime() + (360 * 24 * 60 * 60 * 1000));
                document.cookie = cookieName + ";expires=" + d.toUTCString() + ";path=/";
                cookieAlertElt.classList.add('accepted');
            });
        }
    },

    initFancyLightbox() {
        tools.each(document.querySelectorAll('.grid-component'), gridComponent => {
            if (!tools.isEditMode()) {
                let fancySelectedEl;
                Fancybox.bind(gridComponent, "[data-fancybox]", {

                    Toolbar: {
                        enabled: true,
                        items: {
                            closeButton: {
                                tpl: '<svg width="55" height="55" viewBox="0 0 55 55" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="27.5" cy="27.5" r="26" transform="rotate(-180 27.5 27.5)" stroke="white" stroke-width="3"/><path d="M13.6117 41.3883L41.3914 13.6086" stroke="white" stroke-width="4" stroke-miterlimit="10"/><path d="M41.3914 41.3883L13.6117 13.6086" stroke="white" stroke-width="4" stroke-miterlimit="10"/></svg>',
                            }
                        },
                        display: {
                            right: ["closeButton"],
                        },
                    },

                    Carousel: {
                        Navigation: {
                            prevTpl: '<svg width="55" height="55" viewBox="0 0 55 55" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="27.5" cy="27.5" r="26" transform="rotate(-180 27.5 27.5)" stroke="white" stroke-width="3"/><path d="M34.2649 19.7525L26.524 27.4714L34.2539 35.1903L30.9615 38.4827L19.8952 27.4714L30.9836 16.4602L34.2649 19.7525Z" fill="white"/></svg>',
                            nextTpl: '<svg width="55" height="55" viewBox="0 0 55 55" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="27.5" cy="27.5" r="26" stroke="white" stroke-width="3"/><path d="M20.7351 35.2475L28.476 27.5286L20.7461 19.8097L24.0385 16.5173L35.1048 27.5286L24.0164 38.5398L20.7351 35.2475Z" fill="white"/></svg>',
                        },
                    },

                    Thumbs: false,
                    idle: false,

                    on: {
                        done: (fancybox, e) => {
                            fancySelectedEl = fancybox.container.querySelector('.is-selected');
                            if(!fancySelectedEl.teambubbles) {
                                fancySelectedEl.teambubbles = new TeamBubbles(fancySelectedEl)
                            }
                        },

                        close: () => {
                            fancySelectedEl.teambubbles.hideBubbles();
                        }
                    },
                });

            }

        })

    },

};

document.addEventListener('DOMContentLoaded', () => {
    app.init();

    if (document.location.search.indexOf("mgnlFormToken") >= 0) {
        window.history.replaceState(null, '', document.location.pathname + tools.removeURLParameter(document.location.search, "mgnlFormToken"))
    }
});

window.sukoaUtils = {
    dcmadr: function (nnnn) {
        var a = "";
        for (var i = 0; i < nnnn.length; i++) {
            if (i % 3 === 0) {
                a += String.fromCharCode(nnnn.substr(i, 3));
            }
        }
        location.href = (a);
    }
};
