import Collapsible from "../Collapsible";
const tools = require('../tools');

const basket = {
    props: [
        'items',
        'mode',
        'vat',
        'delivery_costs',
        'delivery_plz',
        'delivery',
        'default_delivery_costs',
        'labels',
        'delivery_cost_per_box',
        'delivery_cost_per_box_outside'

    ],

    data: function() {
        return {

        }
    },

    template:
        `
        <div class="basket-container">
<!--            <transition-group tag="div" name="basket">-->
             <transition-group name="basket" tag="div">
                <div class="basket-item collapsible"
                    :id="item.id"
                    v-for="(item, index) in items"
                    :key="item.id || index"
                    :class="{collapsed: !item.showDetails}"
                >

                    <header>
                        <div class="collapsible-btn">
                            <slot name="collapsible-btn"></slot>
                            <ul>
                                <li class="fontRegular item-name">
                                    <span>{{ item.article.name }}</span>
                                </li>

                                <li class="fontRegular">
                                    <span>
                                        {{ item.amount | number('0,0', { thousandsSeparator: "'" }) }}&nbsp;{{ labels.piece_label }}
                                    </span>
                                </li>
                            </ul>

                        </div>

                        <div class="buttons-costs">
                        <table>
                            <tr>
                                <td>
                                    <div class="button refactorItem">
                                        <p class="page-color" v-on:click="editItem(index)">
                                            <span>{{labels.edit_label}}</span>
                                            <slot name="bubbles"></slot>

                                        </p>
                                    </div>
                                    <div class="button deleteItem">
                                        <p class=" page-color" v-on:click="removeItem(index)">
                                            <span>{{labels.delete_label}}</span>
                                            <slot name="bubbles"></slot>
                                        </p>
                                    </div>
                                </td>
                                <td class="fontRegular" v-if="isMiete()">{{labels.currency_label}}</td>
                                <td class="fontRegular" v-if="isMiete()"><span>{{ item.amount * item.article.preis | currency('', 2, { thousandsSeparator: "'"}) }}</span></td>
                            </tr>
                            </table>
                        </div>

                    </header>
                    <section class="collapsible-zone page-color">
                        <div class="collapsible-area">
                            <div>
                                <p class="item-name fontRegular">{{ item.article.name }}</p>
                                <img v-if="item.article.imageLink" :src="item.article.imageLink" alt="">
                            </div>
                            <div v-if="isMiete()">
                                <table>
                                    <tr>
                                        <td>{{labels.mietpreisprobecher_label}}:</td>
                                        <td>{{labels.currency_label}}</td>
                                        <td>{{ item.article.preis }}</td>
                                    </tr>
                                    <tr>
                                        <td>{{labels.verlustpreisprobecher_label}}:</td>
                                        <td>{{labels.currency_label}}</td>
                                        <td>{{  item.article.verlust }}</td>
                                    </tr>

                                    <tr>
                                        <td>{{labels.verpackungseinheit_label}}:</td>
                                        <td></td>
                                        <td>{{  item.article.vpe }}</td>
                                    </tr>
                                </table>

                                <img width="40px" height="40px" v-if="item.file != null" :src="getImageUrl(item)" />
                            </div>

                            <div v-if="!isMiete()">
                                <img v-if="item.file != null" :src="getImageUrl(item)">
                            </div>
                        </div>

                    </section>

                </div>
            </transition-group>

            <div class="row total" v-if="isMiete()" >
                <div></div>
                <div class="basket-total">
                    <table class="table-total fontRegular">
                        <tr>
                            <td>{{labels.totalexclvat_label}}</td>
                            <td>{{labels.currency_label}}</td>
                            <td> {{ calculateBasketTotalExclVAT  | currency('', 2, { thousandsSeparator: "'"}) }}</td>
                        </tr>
                        <tr v-if="delivery==='lieferung'">
                            <td>{{labels.deliverycosts_label}}</td>
                            <td>{{labels.currency_label}}</td>
                            <td>{{ deliveryCost | currency('', 2, { thousandsSeparator: "'"})  }}</td>
                        </tr>
                        <tr>
                            <td>{{labels.vat_label}}</td>
                            <td>{{labels.currency_label}}</td>
                            <td>{{ calculateVat | currency('', 2, { thousandsSeparator: "'"}) }}</td>
                        </tr>

                        <tr class="total-row">
                            <td>{{labels.totalinclvat_label}}</td>
                            <td>{{labels.currency_label}}</td>
                            <td>{{ calculateBasketTotal | currency('', 2, { thousandsSeparator: "'"}) }}</td>
                        </tr>
                    </table>

                </div>
            </div>
        </div>
        `,

    computed: {
        deliveryCost: function() {
            if (this.delivery==='abholung') {
                return null
            }
            let price = this.default_delivery_costs * 1;


            if (this.items.length <= 0) {
                return price
            }

            let costs = this.delivery_costs.find(e => { return parseInt(this.delivery_plz) >= parseInt(e.from) &&  parseInt(this.delivery_plz) <= parseInt(e.to)});
            let boxes = this.items.map(e => e.amount / e.article.vpe).reduce((x,y) => x +y)

            if (costs !== undefined) {
                price =  costs.price
            }

            if (costs === undefined) {
                return Math.max(price, boxes * this.delivery_cost_per_box_outside)
            } else {
                return Math.max(price, boxes * this.delivery_cost_per_box)
            }
        },

        calculateBasketTotalExclVAT() {
            let totalAmount = 0
            this.items.forEach(item => {
                totalAmount += item.amount * item.article.preis
            })
            return totalAmount
        },

        calculateVat: function() {
            let deliveryCost = (this.deliveryCost!==null?parseFloat(this.deliveryCost):0)
            let vat = (this.calculateBasketTotalExclVAT + deliveryCost) * parseFloat(this.vat) / 100
            return vat
        },

        calculateBasketTotal: function() {
            return Math.round((this.calculateBasketTotalExclVAT + this.calculateVat + this.deliveryCost) * 20) / 20
        }

    },

    methods: {
        getImageUrl: function(item) {
            if(item.file) {
                return URL.createObjectURL(item.file);
            }
        },

        isMiete() {
            return this.mode === 'miete';
        },

        clickDetails: function(item) {
            item.showDetails = !item.showDetails
        },

        removeItem(index) {
            this.$emit('remove-item', index);
        },

        toFixed: function(number, decimals) {
             var x = Math.pow(10, Number(decimals) + 1);
             return (Number(number) + (1 / x)).toFixed(decimals)
        },

        editItem(index) {
            this.$emit('edit-item', index);
        }
    },

    mounted() {
        tools.each(this.$el.querySelectorAll('.collapsible'), elt => new Collapsible(elt, '.collapsible-btn'));
    },

    updated() {
        tools.each(this.$el.querySelectorAll('.collapsible'), elt => new Collapsible(elt, '.collapsible-btn'));
    }
}

export default basket;
