const tools = require('../tools');
import { v4 as uuidv4} from 'uuid';

const mieteProductInfo = {
    props: [
        'selection',
        'update_mode',
        'labels'
    ],

    data(vueObj) {
        return {
            addAmount: null,
            updated: false,
        }
    },

    template: `
        <section class="info">
            <div class="row row_1">
                <div class="left">
                    <p class="packaging">{{labels.verpackungseinheit_label}}:&nbsp;&nbsp;&nbsp;{{ selected_article.vpe }}</p>
                </div>

                <div class="right article-container">
                    <div class="article">
                        <table>
                            <tr>
                                <td>{{labels.mietpreisprobecher_label}}:</td>
                                <td>{{labels.currency_label}}</td>
                                <td>{{ selected_article.preis }}</td>
                            </tr>
                            <tr>
                                <td>{{labels.verlustpreisprobecher_label}}:</td>
                                <td>{{labels.currency_label}}</td>
                                <td>{{ selected_article.verlust }}</td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
            <div class="row row_2">
                <div class="left fontRegular">
                    {{labels.bechermenge_label}}
                    <div class="qtywrapper" :class="{error: qtyMatchNotVPE}">
                        <span :class="{active:qtyMatchNotVPE && addAmount >= vpe}" class="round" v-on:click="updateQty(-1)"
                            :title="labels.round_down_label"
                        >
                            <svg fill='none' stroke='#000' stroke-width='10' stroke-dashoffset='0' stroke-dasharray='0' stroke-linecap='round' stroke-linejoin='round' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100'><line x1="20" y1="80" x2="80" y2="80" />
                                <line x1="50" y1="15" x2="50" y2="65" />
                                <polyline fill="none" points="30,50 50,65 70,50" />
                                </svg>
                        </span>
                        <input class="qtyfield input" type="text" v-model.number="addAmount" v-on:keyup.enter="addItem($event)" v-on:blur="checkValue()" @keypress="isNumber"/>
                        <span :class="{active: qtyMatchNotVPE}" class="round up" v-on:click="updateQty(1)"
                             :title="labels.round_up_label"
                        >
                            <svg fill='none' stroke='#000' stroke-width='10' stroke-dashoffset='0' stroke-dasharray='0' stroke-linecap='round' stroke-linejoin='round' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100'><line x1="20" y1="20" x2="80" y2="20" />
                                <line x1="50" y1="35" x2="50" y2="85" />
                                <polyline fill="none" points="30,50 50,35 70,50" />
                            </svg>
                        </span>
                    </div>
                    {{labels.piece_label}}
                    <div class="fontSmall">{{labels.article_countround}}</div>
                </div>

                <div class="right">
                    <span>{{labels.mwstinfo_label}}</span>
                </div>
            </div>
            <div class="row row_3 add_item_row" :class="{visible: selectionValid}">
                <div class="left"></div>
                <div class="right">
                    <table class="price-table">
                        <tr>
                            <td>
                                <div class="button addItem">
                                    <p v-on:click="addItem()" class="page-color">
                                        <span v-html="getAddBtnLabel()"></span>
                                        <slot></slot>
                                    </p>
                                </div>
                                <div class="button cancel" v-if="update_mode">
                                    <p v-on:click="cancelUpdate()" class="page-color">
                                        <span>{{labels.cancel_label}}</span>
                                        <slot></slot>
                                    </p>
                                </div>
                            </td>
                            <td class="fontRegular">{{labels.currency_label}}</td>
                            <td class="fontRegular"><span>{{ calculatedPrice | currency('', 2, { thousandsSeparator: "'"}) }}</span></td>
                        </tr>
                    </table>
                </div>
            </div>
        </section>
    `,

    computed: {

        calculatedPrice() {
            return this.selected_article.preis * this.addAmount
        },

        selected_article() {
            return (this.selection !== null && this.selection.article !== null) ? this.selection.article : null;
        },

        getSelectionAmount() {
            let amount = '';
            if(this.selection.amount !== null && this.selection.amount > 0) {
                amount =  this.selection.amount;
            }
            return amount;
        },

        vpe() {
            return this.selected_article.vpe | 0;
        },

        qtyMatchNotVPE() {
            return this.addAmount && this.vpe !== 0 && this.addAmount % this.vpe !== 0;
        },

        selectionValid() {
          return this.addAmount && this.addAmount >= 0 && this.addAmount % this.vpe === 0;
        }
    },

    methods: {

        checkValue() {
            setTimeout(() => {
                if(this.qtyMatchNotVPE) {
                    this.updateQty(1)
                }
            }, 700)
        },

        updateQty(coef) {
            let modAddAmount = this.addAmount;
            if(this.vpe !== 0) {
                modAddAmount = coef < 0 ? Math.floor( modAddAmount / this.vpe) * this.vpe: Math.ceil( modAddAmount / this.vpe) * this.vpe;
            }
            this.addAmount = modAddAmount;
        },

        cancelUpdate() {
            this.$emit('cancel-update');
        },

        getAddBtnLabel() {
            return this.update_mode ? this.labels.update_label : this.labels.add_label
        },


        addItem() {
            if(this.qtyMatchNotVPE) {
                this.updateQty(1);
                return
            }

            let x;
            if (this.vpe > 0) {
                x = Math.ceil(this.addAmount / this.vpe) * this.vpe;
            } else {
                x = this.addAmount
            }

            if (x <= 0) {
                return
            }

            this.selection.setAmount(x);
            this.$emit('add-to-basket', this.selection);
        },

        isNumber(evt) {return tools.isNumber(evt)}
    },

    beforeUpdate() {
        if(this.update_mode && !this.addAmount) {
            this.addAmount = this.selection.amount > 0 ? this.selection.amount : null
        }
    },

    mounted() {
        if(this.update_mode && !this.addAmount) {
            this.addAmount = this.selection.amount > 0 ? this.selection.amount : null
        }
    }
}

export default mieteProductInfo;
