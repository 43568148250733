class UserDevice {
    constructor() {
        this._touch = false
        this._small = 749;
        this._medium = 1024;
        this.init();
    }

    init() {
        this.checkTouch();
    }

    checkTouch() {

        if (window.innerWidth <= this._small) {
            this._touch = true;
            document.body.classList.add("touch");
        } else {

            let self = this;
            window.addEventListener('touchstart', function onFirstTouch() {
                document.body.classList.add("touch");
                self._touch = true;
                window.removeEventListener('touchstart', onFirstTouch, {passive: true});
            }, {passive: true})

        }
    }

    getHeight() { return window.innerHeight;}
    getWidth() { return window.innerWidth;}
    isTouch() { return this._touch;}
    isSmall() {return this._small >= window.innerWidth;}
    isMedium() {return !this.isSmall() && this._medium >= window.innerWidth;}
    isLarge() {return this._medium < window.innerWidth;}

    detectSafari() {
        return navigator.userAgent.indexOf('Safari') !== -1 && navigator.userAgent.indexOf('Chrome') === -1;
    }

    detectIE() {
        var ua = window.navigator.userAgent;

        var msie = ua.indexOf('MSIE ');
        if (msie > 0) {
            // IE 10 or older => return version number
            return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
        }

        var trident = ua.indexOf('Trident/');
        if (trident > 0) {
            // IE 11 => return version number
            var rv = ua.indexOf('rv:');
            return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
        }

        var edge = ua.indexOf('Edge/');
        if (edge > 0) {
            // Edge (IE 12+) => return version number
            return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
        }

        // other browser
        return false;
    }

    detectEdge () {
        var ua = window.navigator.userAgent;

        var edge = ua.indexOf('Edge/');
        if (edge > 0) {
            return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
        }

        // other browser
        return false;
    }
}

export default UserDevice;
