import Selection from './Selection';
import kaufProductInfo from "./kaufProductInfo";
import mieteProductInfo from "./mieteProductInfo";

const selectionComp = {
    props: ['labels', 'selection', 'update_mode', 'tab_active', 'tab_id', 'article_groups', 'mode', 'min_order_amount_data'],

    data(vm) {
        return {
            storedImages: [],
            selectedPrint: null,
        }
    },

    template: `
        <section class="selection-wrapper" :class="[{active: isTabActive()}, isMiete() ? 'isMiete' : 'isKauf']">
        <div class="selection">
            <div class="grid-container product-container">
                <div class="grid-item articleGroup"
                     v-for="articleGroup in article_groups"
                     v-if="articleGroup.articles.length > 0"
                     :key="articleGroup.id"
                     :class="{active: selection.isSameGroup(articleGroup)}"
                     @click="selection.setGroup(articleGroup)"
                >
                    <div :title="articleGroup.label" v-html="articleGroup.svg"></div>
                    <span class="group-label fontSmall" v-html="articleGroup.label"></span>
                </div>
            </div>

            <div class="select-info">

                <div class="size-optic-print-image">
                    <div class="grid-container sizes-container"
                         v-if="selection.hasSizes()"
                    >
                        <div class="grid-item sizes button"
                             v-for="size in selection.getSizes()"
                             :key="size.label"
                             v-bind:class="{active: selection.isSameSize(size)}"
                             @click="selection.setSize(size)"
                        >
                        <span>
                            {{ size.label }}
                        </span>
                        </div>
                    </div>

                    <div class="grid-container optics-container">
                        <div class="grid-item optics button"
                             v-if="selection.hasOptics()"
                             v-for="optic in selection.getOptics()"
                             :key="optic.label"
                             v-bind:class="{active: selection.isSameOptic(optic)}"
                             @click="selection.setOptic(optic)"
                        >
                        <span>
                            {{ optic.label }}
                        </span>
                        </div>
                    </div>

                    <div class="grid-container print-container" v-if="getPrints.length > 0">
                        <div class="grid-item">
                            <div class="fontRegular print-select-wrapper">

                                <select v-model="selectedPrint" v-on:change="updatePrint" class="input button">
                                    <option :value="null" disabled>{{labels.pleasechoose_label}}</option>
                                    <option v-for="print in getPrints" v-bind:value="print.articleId">
                                        {{ print.label }}
                                    </option>
                                </select>
                                <div class="selectarrow page-color">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="18" viewBox="0 0 32 18">
                                        <line class="page-color-stroke" x1="2" y1="1" x2="16" y2="17" stroke-width="3"></line>
                                        <line class="page-color-stroke" x1="30" y1="1" x2="14" y2="17" stroke-width="3"></line>
                                   </svg>
                                </div>
                            </div>
                        </div>

                        <!-- Display image here on "Kaufen" page -->
                        <div class="grid-container articleImage-container" :class="{visible: selection.hasArticle()}" v-if="!isMiete() && selection.hasArticle()">
                            <img v-if="selection.article.imageLink !== null" :src="imageLink">
<!--                            <div v-else class="no-image"><div class="sizer"></div></div>-->
                            <p class="article-description">{{ selection.article.name }}</p>
                        </div>

                    </div>

                    <!-- Display image here on "Mieten" page -->
                     <div class="grid-container articleImage-container" :class="{visible: selection.hasArticle()}" v-if="isMiete() && selection.hasArticle()">
                        <img v-if="selection.article.imageLink !== null" :src="imageLink">
                        <div v-else class="no-image"><div class="sizer"></div></div>
                        <p class="article-description">{{ selection.article.name }}</p>
                    </div>
                </div>

                <kauf-product-info v-if="kaufArticleSelected"
                                   :labels="labels"
                                   :selection="selection"
                                   :update_mode="update_mode"
                                   v-on:add-to-basket="addToBasket"
                                   v-on:cancel-update="cancelUpdate"
                >
                    <slot name="bubbles"></slot>
                </kauf-product-info>
            </div>

           </div>
           <miete-product-info
               v-if="mieteArticleSelected"
               :labels="labels"
               :selection="selection"
               :update_mode="update_mode"
               v-on:add-to-basket="addToBasket"
               v-on:cancel-update="cancelUpdate"

           >
               <slot name="bubbles"></slot>
           </miete-product-info>
        </section>
    `,

    components: {
        'miete-product-info': mieteProductInfo,
        'kauf-product-info': kaufProductInfo,
    },

    computed: {
        mieteArticleSelected() {
            return  this.isMiete() && this.selection.hasArticle();
        },

        kaufArticleSelected() {
            return !this.isMiete() && this.selection.hasArticle();
        },

        getPrints() {
            this.selectedPrint = null;
            return this.selection.getPrints()
        },

        imageLink() {
            if (this.storedImages.indexOf(this.selection.article.imageLink) !== -1) {
                return this.selection.article.imageLink;
            } else {
                const image = new Image();
                image.onload = () => this.storedImages.push(this.selection.article.imageLink);
                image.src = this.selection.article.imageLink;
            }
        }

    },

    methods: {

        minOrderAmount() {
            let minOrderAmount = null;
            if (this.selection.print) {
                let printValue = this.selection.print.value;
                minOrderAmount = (this.min_order_amount_data[printValue] !== null && typeof this.min_order_amount_data[printValue] !== 'undefined') ? this.min_order_amount_data[printValue] : 0;
            }
            return minOrderAmount;
        },

        isMiete() {
            return this.mode === 'miete';
        },

        isTabActive() {
            if (this.tab_active) {
                this.selection.setTabId(this.tab_id);
            }
            return this.tab_active;
        },

        addToBasket(selection) {
            this.$emit('add-to-basket', selection);
        },

        cancelUpdate() {
            console.log('cancel relayed by selectioncomp');
            this.$emit('cancel-update');
        },

        updatePrint(evt) {
            let print = this.selection.getPrints().find(e => e.articleId === evt.target.value)

            if(print) {
                this.selection.setPrint(print);
                this.selection.setMinOrderAmount(this.minOrderAmount());
            }
        }
    },
}


export default selectionComp;
