const tools = {
    ajxGet(url, options) {
        tools.ajx('GET', url, options)
    },

    ajxPost(url, options) {
        tools.ajx('POST', url, options)
    },

    ajx(method, url, options) {
        let req = null;
        if (url) {
            req = new XMLHttpRequest();

            req.onreadystatechange = function () {

                if (this.readyState === XMLHttpRequest.DONE && this.status === 200) {
                    if (options.fallback) {
                        options.fallback(req.response);
                    }
                }
            };

            let params = null;
            if (options.formData && options.formData instanceof FormData) {
                params = '';
                params += params.indexOf('?') === -1 ? '?' : '&';
                for (let entry of options.formData.entries()) {
                    params += entry[0] + '=' + entry[1] + '&';
                }
                params = params.substr(0, params.length - 1);
            }

            if (options.replaceHistory) {
                tools.replaceHistoryState(url);
            }

            req.open(method, url, true);
            if(params) {
                req.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
            }
            req.responseType = options.responseType || 'text';
            req.send(params);
        } else if(options.fallback){
            options.fallback({error: 'url not provided'})
        }
        return req;
    },



    each(list, fn) {
        if(list && fn) {
            for(let i = 0; i < list.length; i++) {
                fn(list[i]);
            }
        }
    },

    getCurrentURL : function(excludeParams){
        var url = window.location.href;
        if(excludeParams){
            var i = url.indexOf("?");
            if(i>-1){
                url = url.substring(0,i);
            }
            var j = url.indexOf("#");
            if(j>-1){
                url = url.substring(0,j);
            }
        }
        return url;
    },

    getParameterByName: function (name) {
        name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
        var regex = new RegExp("[\\?&]" + name + "=([^&#]*)"), results = regex.exec(location.search);
        return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
    },

    isEditMode() {return document.body.classList.contains('editmode')},

    removeURLParameter(url, parameter) {
        //prefer to use l.search if you have a location/link object
        var urlparts = url.split('?');
        if (urlparts.length >= 2) {

            var prefix = encodeURIComponent(parameter) + '=';
            var pars = urlparts[1].split(/[&;]/g);

            //reverse iteration as may be destructive
            for (var i = pars.length; i-- > 0;) {
                //idiom for string.startsWith
                if (pars[i].lastIndexOf(prefix, 0) !== -1) {
                    pars.splice(i, 1);
                }
            }
            return urlparts[0] + (pars.length > 0 ? '?' + pars.join('&') : '');
        }
        return url;
    },

    doOnWindowResizeList: [],
    doOnScrollEndList: [],
    doOnScrollList: [],
    windowScrolListenerInitialised: false,
    windowResizeListenerInitialised: false,

    scrollTo(elt, destination, duration = 300, fb) {

        let start, distance, timeStart;

        function proceed() {
            start = elt === window ? (window.pageYOffset || window.scrollY) : elt.scrollTop;
            distance = destination - start;
            timeStart = null;
            requestAnimationFrame(loop);
        }

        function loop(time) {
            if (!timeStart) {
                timeStart = time;
            }

            let timeElapsed = time - timeStart;

            if (elt === window) {
                window.scrollTo(0, ease(timeElapsed, start, distance, duration))
            } else {
                elt.scrollTop = ease(timeElapsed, start, distance, duration);
            }

            if (timeElapsed < duration) {
                requestAnimationFrame(loop)
            } else {
                if (elt === window) {
                    window.scrollTo(0, destination)
                } else {
                    elt.scrollTop = destination;
                }
                timeStart = false;
                if (fb) {
                    fb(elt);
                }
            }
        }

        proceed();

        function ease(t, b, c, d) {
            t /= d / 2;
            if (t < 1) return c / 2 * t * t + b;
            t--;
            return -c / 2 * (t * (t - 2) - 1) + b
        }
    },

    easeTo(start, target, action, duration = 300) {
        if (start && target && action) {
            let distance, timeStart;

            function proceed() {
                distance = target - start;
                timeStart = null;
                requestAnimationFrame(loop);
            }

            function loop(time) {
                if (!timeStart) {
                    timeStart = time;
                }
                let timeElapsed = time - timeStart;
                //todo: result rounding as param
                action(Math.round(ease(timeElapsed, start, distance, duration)));
                if (timeElapsed < duration) {
                    requestAnimationFrame(loop)
                } else {
                    action(target);
                    timeStart = false;
                }
            }

            proceed();

            function ease(t, b, c, d) {
                t /= d / 2;
                if (t < 1) return c / 2 * t * t + b;
                t--;
                return -c / 2 * (t * (t - 2) - 1) + b
            }
        }
    },

    doOnWindowResize(action) {
        if (action && action.fn) {
            this.doOnWindowResizeList.push(action);
            if(!this.windowResizeListenerInitialised) {
                tools.windowResizeListener();
            }
        }
    },

    onWindowResizeActions() {
        tools.each(this.doOnWindowResizeList, action => {
            action.fn(action.arg);
        });
    },

    windowResizeListener() {
        let timeoutFn = null;
        window.onresize = (e) => {
            if(timeoutFn != null) {window.clearTimeout(timeoutFn);}
            timeoutFn = setTimeout(() => {
                tools.onWindowResizeActions();
            }, 100);
        };
        tools.windowResizeListenerInitialised = true;
    },

    doOnScroll(action, duringScroll, scrollEnd) {
        if (action && action.fn) {
            if(duringScroll) {
                this.doOnScrollList.push(action)
            }
            if(scrollEnd) {
                this.doOnScrollEndList.push(action);
            }
            if(!tools.windowScrolListenerInitialised) {
                tools.windowScrollListener();
            }
        }
    },

    onScrollActions() {
        tools.each(this.doOnScrollList, action => {
            action.fn(action.arg)
        });
    },

    onScrollEndActions() {
        tools.each(this.doOnScrollEndList, action => {
            action.fn(action.arg)
        });
    },

    windowScrollListener(fct) {
        let timeoutFn = null;
        window.addEventListener('scroll', (e) => {
            tools.onScrollActions();
            if(timeoutFn != null) {window.clearTimeout(timeoutFn);}
            timeoutFn = setTimeout(() => {
                tools.onScrollEndActions();
            }, 100);
        })
        tools.windowScrolListenerInitialised = true;
    },

    setInterval(fn, delay) {

        let intervalObj = {
            start: Date.now(),
            stop: false,
            delay: delay,
            fn: fn,
            intervalFn: () => {
                Date.now() - intervalObj.start < intervalObj.delay || (intervalObj.start += intervalObj.delay, intervalObj.fn());
                intervalObj.stop || requestAnimationFrame(intervalObj.intervalFn);
            },
            clear: () => intervalObj.stop = true
        }
        requestAnimationFrame(intervalObj.intervalFn)
        return intervalObj;
    },

    isNumber(evt) {
        evt = (evt) ? evt : window.event;
        let charCode = (evt.which) ? evt.which : evt.keyCode;
        if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
            evt.preventDefault();
        } else {
            return true;
        }
    }
};

module.exports = tools;
